export default defineNuxtRouteMiddleware((to, from) => {
  const token = useCookie('token');

  if (!token.value && to.path !== generateLink('login')) {
    return navigateTo(generateLink('login'));
  }

  // switch (to.path) {
  //   case generateLink('account'):
  //     if (!token.value) {
  //       return navigateTo(generateLink('login'));
  //     }
  //     break;
  //   case generateLink('login'):
  //     if (token.value) {
  //       return navigateTo(generateLink('account'));
  //     }
  //     break;
  // }
});
